// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-druk-3-d-js": () => import("./../../../src/pages/druk-3d.js" /* webpackChunkName: "component---src-pages-druk-3-d-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inne-uslugi-js": () => import("./../../../src/pages/inne-uslugi.js" /* webpackChunkName: "component---src-pages-inne-uslugi-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-modele-js": () => import("./../../../src/pages/modele.js" /* webpackChunkName: "component---src-pages-modele-js" */)
}

